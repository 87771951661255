import { default as complete_45profileXlyuCtO8EnMeta } from "/Users/jflower/devProjects/uwit/pages/auth/complete-profile.vue?macro=true";
import { default as create_45accountTYkxrcVqkEMeta } from "/Users/jflower/devProjects/uwit/pages/auth/create-account.vue?macro=true";
import { default as forgot_45passwordVqX68LwvRWMeta } from "/Users/jflower/devProjects/uwit/pages/auth/forgot-password.vue?macro=true";
import { default as manage_45accountMJgAlZgwqnMeta } from "/Users/jflower/devProjects/uwit/pages/auth/manage-account.vue?macro=true";
import { default as save_45usernamejR0bjASXlqMeta } from "/Users/jflower/devProjects/uwit/pages/auth/save-username.vue?macro=true";
import { default as sign_45in_45emailcJnt2wB7aTMeta } from "/Users/jflower/devProjects/uwit/pages/auth/sign-in-email.vue?macro=true";
import { default as sign_45in_45socialNaBtUL153tMeta } from "/Users/jflower/devProjects/uwit/pages/auth/sign-in-social.vue?macro=true";
import { default as sign_45up_45userMlhAiL8AjcMeta } from "/Users/jflower/devProjects/uwit/pages/auth/sign-up-user.vue?macro=true";
import { default as sign_45upRdxPxZAyw7Meta } from "/Users/jflower/devProjects/uwit/pages/auth/sign-up.vue?macro=true";
import { default as correct_45answerm90aADhw5sMeta } from "/Users/jflower/devProjects/uwit/pages/game/correct-answer.vue?macro=true";
import { default as dashboardPpWB4cBAfGMeta } from "/Users/jflower/devProjects/uwit/pages/game/dashboard.vue?macro=true";
import { default as descriptionaUZmfuPxOGMeta } from "/Users/jflower/devProjects/uwit/pages/game/description.vue?macro=true";
import { default as leaderboardlAnymcGbGzMeta } from "/Users/jflower/devProjects/uwit/pages/game/leaderboard.vue?macro=true";
import { default as questionIYKal0VETNMeta } from "/Users/jflower/devProjects/uwit/pages/game/question.vue?macro=true";
import { default as times_45upCQKPYT8ImbMeta } from "/Users/jflower/devProjects/uwit/pages/game/times-up.vue?macro=true";
import { default as uwitzZshd5Amd4Meta } from "/Users/jflower/devProjects/uwit/pages/game/uwit.vue?macro=true";
import { default as welcome_45backimgI1Rpd3nMeta } from "/Users/jflower/devProjects/uwit/pages/game/welcome-back.vue?macro=true";
import { default as wrong_45answerP1Bl8wdaMOMeta } from "/Users/jflower/devProjects/uwit/pages/game/wrong-answer.vue?macro=true";
import { default as indexs0JhFZwo5MMeta } from "/Users/jflower/devProjects/uwit/pages/index.vue?macro=true";
import { default as page28LmRQJgieerMeta } from "/Users/jflower/devProjects/uwit/pages/page28.vue?macro=true";
import { default as index7eMOzhfW4oMeta } from "/Users/jflower/devProjects/uwit/pages/sabbath/index.vue?macro=true";
import { default as user_45simulationiqVxJEVc7OMeta } from "/Users/jflower/devProjects/uwit/pages/user-simulation.vue?macro=true";
import { default as step2GsTRSPHEQGMeta } from "/Users/jflower/devProjects/uwit/pages/welcome/step2.vue?macro=true";
import { default as step32YSE4VnxrOMeta } from "/Users/jflower/devProjects/uwit/pages/welcome/step3.vue?macro=true";
import { default as step4wglfTZydqlMeta } from "/Users/jflower/devProjects/uwit/pages/welcome/step4.vue?macro=true";
import { default as step5AYa4qmNblJMeta } from "/Users/jflower/devProjects/uwit/pages/welcome/step5.vue?macro=true";
export default [
  {
    name: complete_45profileXlyuCtO8EnMeta?.name ?? "auth-complete-profile___en",
    path: complete_45profileXlyuCtO8EnMeta?.path ?? "/auth/complete-profile",
    meta: complete_45profileXlyuCtO8EnMeta || {},
    alias: complete_45profileXlyuCtO8EnMeta?.alias || [],
    redirect: complete_45profileXlyuCtO8EnMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: create_45accountTYkxrcVqkEMeta?.name ?? "auth-create-account___en",
    path: create_45accountTYkxrcVqkEMeta?.path ?? "/auth/create-account",
    meta: create_45accountTYkxrcVqkEMeta || {},
    alias: create_45accountTYkxrcVqkEMeta?.alias || [],
    redirect: create_45accountTYkxrcVqkEMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/create-account.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordVqX68LwvRWMeta?.name ?? "auth-forgot-password___en",
    path: forgot_45passwordVqX68LwvRWMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordVqX68LwvRWMeta || {},
    alias: forgot_45passwordVqX68LwvRWMeta?.alias || [],
    redirect: forgot_45passwordVqX68LwvRWMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: manage_45accountMJgAlZgwqnMeta?.name ?? "auth-manage-account___en",
    path: manage_45accountMJgAlZgwqnMeta?.path ?? "/auth/manage-account",
    meta: manage_45accountMJgAlZgwqnMeta || {},
    alias: manage_45accountMJgAlZgwqnMeta?.alias || [],
    redirect: manage_45accountMJgAlZgwqnMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/manage-account.vue").then(m => m.default || m)
  },
  {
    name: save_45usernamejR0bjASXlqMeta?.name ?? "auth-save-username___en",
    path: save_45usernamejR0bjASXlqMeta?.path ?? "/auth/save-username",
    meta: save_45usernamejR0bjASXlqMeta || {},
    alias: save_45usernamejR0bjASXlqMeta?.alias || [],
    redirect: save_45usernamejR0bjASXlqMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/save-username.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45emailcJnt2wB7aTMeta?.name ?? "auth-sign-in-email___en",
    path: sign_45in_45emailcJnt2wB7aTMeta?.path ?? "/auth/sign-in-email",
    meta: sign_45in_45emailcJnt2wB7aTMeta || {},
    alias: sign_45in_45emailcJnt2wB7aTMeta?.alias || [],
    redirect: sign_45in_45emailcJnt2wB7aTMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/sign-in-email.vue").then(m => m.default || m)
  },
  {
    name: sign_45in_45socialNaBtUL153tMeta?.name ?? "auth-sign-in-social___en",
    path: sign_45in_45socialNaBtUL153tMeta?.path ?? "/auth/sign-in-social",
    meta: sign_45in_45socialNaBtUL153tMeta || {},
    alias: sign_45in_45socialNaBtUL153tMeta?.alias || [],
    redirect: sign_45in_45socialNaBtUL153tMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/sign-in-social.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45userMlhAiL8AjcMeta?.name ?? "auth-sign-up-user___en",
    path: sign_45up_45userMlhAiL8AjcMeta?.path ?? "/auth/sign-up-user",
    meta: sign_45up_45userMlhAiL8AjcMeta || {},
    alias: sign_45up_45userMlhAiL8AjcMeta?.alias || [],
    redirect: sign_45up_45userMlhAiL8AjcMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/sign-up-user.vue").then(m => m.default || m)
  },
  {
    name: sign_45upRdxPxZAyw7Meta?.name ?? "auth-sign-up___en",
    path: sign_45upRdxPxZAyw7Meta?.path ?? "/auth/sign-up",
    meta: sign_45upRdxPxZAyw7Meta || {},
    alias: sign_45upRdxPxZAyw7Meta?.alias || [],
    redirect: sign_45upRdxPxZAyw7Meta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: correct_45answerm90aADhw5sMeta?.name ?? "game-correct-answer___en",
    path: correct_45answerm90aADhw5sMeta?.path ?? "/game/correct-answer",
    meta: correct_45answerm90aADhw5sMeta || {},
    alias: correct_45answerm90aADhw5sMeta?.alias || [],
    redirect: correct_45answerm90aADhw5sMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/correct-answer.vue").then(m => m.default || m)
  },
  {
    name: dashboardPpWB4cBAfGMeta?.name ?? "game-dashboard___en",
    path: dashboardPpWB4cBAfGMeta?.path ?? "/game/dashboard",
    meta: dashboardPpWB4cBAfGMeta || {},
    alias: dashboardPpWB4cBAfGMeta?.alias || [],
    redirect: dashboardPpWB4cBAfGMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/dashboard.vue").then(m => m.default || m)
  },
  {
    name: descriptionaUZmfuPxOGMeta?.name ?? "game-description___en",
    path: descriptionaUZmfuPxOGMeta?.path ?? "/game/description",
    meta: descriptionaUZmfuPxOGMeta || {},
    alias: descriptionaUZmfuPxOGMeta?.alias || [],
    redirect: descriptionaUZmfuPxOGMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/description.vue").then(m => m.default || m)
  },
  {
    name: leaderboardlAnymcGbGzMeta?.name ?? "game-leaderboard___en",
    path: leaderboardlAnymcGbGzMeta?.path ?? "/game/leaderboard",
    meta: leaderboardlAnymcGbGzMeta || {},
    alias: leaderboardlAnymcGbGzMeta?.alias || [],
    redirect: leaderboardlAnymcGbGzMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: questionIYKal0VETNMeta?.name ?? "game-question___en",
    path: questionIYKal0VETNMeta?.path ?? "/game/question",
    meta: questionIYKal0VETNMeta || {},
    alias: questionIYKal0VETNMeta?.alias || [],
    redirect: questionIYKal0VETNMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/question.vue").then(m => m.default || m)
  },
  {
    name: times_45upCQKPYT8ImbMeta?.name ?? "game-times-up___en",
    path: times_45upCQKPYT8ImbMeta?.path ?? "/game/times-up",
    meta: times_45upCQKPYT8ImbMeta || {},
    alias: times_45upCQKPYT8ImbMeta?.alias || [],
    redirect: times_45upCQKPYT8ImbMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/times-up.vue").then(m => m.default || m)
  },
  {
    name: uwitzZshd5Amd4Meta?.name ?? "game-uwit___en",
    path: uwitzZshd5Amd4Meta?.path ?? "/game/uwit",
    meta: uwitzZshd5Amd4Meta || {},
    alias: uwitzZshd5Amd4Meta?.alias || [],
    redirect: uwitzZshd5Amd4Meta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/uwit.vue").then(m => m.default || m)
  },
  {
    name: welcome_45backimgI1Rpd3nMeta?.name ?? "game-welcome-back___en",
    path: welcome_45backimgI1Rpd3nMeta?.path ?? "/game/welcome-back",
    meta: welcome_45backimgI1Rpd3nMeta || {},
    alias: welcome_45backimgI1Rpd3nMeta?.alias || [],
    redirect: welcome_45backimgI1Rpd3nMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/welcome-back.vue").then(m => m.default || m)
  },
  {
    name: wrong_45answerP1Bl8wdaMOMeta?.name ?? "game-wrong-answer___en",
    path: wrong_45answerP1Bl8wdaMOMeta?.path ?? "/game/wrong-answer",
    meta: wrong_45answerP1Bl8wdaMOMeta || {},
    alias: wrong_45answerP1Bl8wdaMOMeta?.alias || [],
    redirect: wrong_45answerP1Bl8wdaMOMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/game/wrong-answer.vue").then(m => m.default || m)
  },
  {
    name: indexs0JhFZwo5MMeta?.name ?? "index___en",
    path: indexs0JhFZwo5MMeta?.path ?? "/",
    meta: indexs0JhFZwo5MMeta || {},
    alias: indexs0JhFZwo5MMeta?.alias || [],
    redirect: indexs0JhFZwo5MMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/index.vue").then(m => m.default || m)
  },
  {
    name: page28LmRQJgieerMeta?.name ?? "page28___en",
    path: page28LmRQJgieerMeta?.path ?? "/page28",
    meta: page28LmRQJgieerMeta || {},
    alias: page28LmRQJgieerMeta?.alias || [],
    redirect: page28LmRQJgieerMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/page28.vue").then(m => m.default || m)
  },
  {
    name: index7eMOzhfW4oMeta?.name ?? "sabbath___en",
    path: index7eMOzhfW4oMeta?.path ?? "/sabbath",
    meta: index7eMOzhfW4oMeta || {},
    alias: index7eMOzhfW4oMeta?.alias || [],
    redirect: index7eMOzhfW4oMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/sabbath/index.vue").then(m => m.default || m)
  },
  {
    name: user_45simulationiqVxJEVc7OMeta?.name ?? "user-simulation___en",
    path: user_45simulationiqVxJEVc7OMeta?.path ?? "/user-simulation",
    meta: user_45simulationiqVxJEVc7OMeta || {},
    alias: user_45simulationiqVxJEVc7OMeta?.alias || [],
    redirect: user_45simulationiqVxJEVc7OMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/user-simulation.vue").then(m => m.default || m)
  },
  {
    name: step2GsTRSPHEQGMeta?.name ?? "welcome-step2___en",
    path: step2GsTRSPHEQGMeta?.path ?? "/welcome/step2",
    meta: step2GsTRSPHEQGMeta || {},
    alias: step2GsTRSPHEQGMeta?.alias || [],
    redirect: step2GsTRSPHEQGMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/welcome/step2.vue").then(m => m.default || m)
  },
  {
    name: step32YSE4VnxrOMeta?.name ?? "welcome-step3___en",
    path: step32YSE4VnxrOMeta?.path ?? "/welcome/step3",
    meta: step32YSE4VnxrOMeta || {},
    alias: step32YSE4VnxrOMeta?.alias || [],
    redirect: step32YSE4VnxrOMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/welcome/step3.vue").then(m => m.default || m)
  },
  {
    name: step4wglfTZydqlMeta?.name ?? "welcome-step4___en",
    path: step4wglfTZydqlMeta?.path ?? "/welcome/step4",
    meta: step4wglfTZydqlMeta || {},
    alias: step4wglfTZydqlMeta?.alias || [],
    redirect: step4wglfTZydqlMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/welcome/step4.vue").then(m => m.default || m)
  },
  {
    name: step5AYa4qmNblJMeta?.name ?? "welcome-step5___en",
    path: step5AYa4qmNblJMeta?.path ?? "/welcome/step5",
    meta: step5AYa4qmNblJMeta || {},
    alias: step5AYa4qmNblJMeta?.alias || [],
    redirect: step5AYa4qmNblJMeta?.redirect || undefined,
    component: () => import("/Users/jflower/devProjects/uwit/pages/welcome/step5.vue").then(m => m.default || m)
  }
]