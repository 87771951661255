
import { defuFn } from '/Users/jflower/devProjects/uwit/node_modules/.pnpm/defu@6.1.3/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyAuChMsyJyrJO8L7AxJ0tI8hRNUlensfWs",
    "authDomain": "dev.uwit.app",
    "projectId": "uwd-dev",
    "storageBucket": "uwd-dev.appspot.com",
    "messagingSenderId": "957442558962",
    "appId": "1:957442558962:web:7bd8cdca507ae20109796b",
    "measurementId": "G-K540859R26"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": false,
    "config": {
      "apiKey": "AIzaSyAuChMsyJyrJO8L7AxJ0tI8hRNUlensfWs",
      "authDomain": "dev.uwit.app",
      "projectId": "uwd-dev",
      "storageBucket": "uwd-dev.appspot.com",
      "messagingSenderId": "957442558962",
      "appId": "1:957442558962:web:7bd8cdca507ae20109796b",
      "measurementId": "G-K540859R26"
    }
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
